import React, {useState, useEffect} from 'react';
import { Form  } from "react-bootstrap";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import { Modal , Row, Col } from "react-bootstrap";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Map from '../google-map/map';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Loader from '../loader/loader'
import { DatasetController } from 'chart.js';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { useHistory, useParams } from "react-router-dom";

const columns = [
    { id: '1', label: 'Settings Name', minWidth: 50,span: false },
    { id: '2', label: 'Account', minWidth: 50,span: false },
    {
      id: 'Action',
      label: 'Action',
      minWidth: 100,
      span: true
    },
  ];
  
  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

function Commonsettings(props) {
  let history = useHistory();
  const [usercompanyid, setUsercompanyid] = useState("")
  const [userbranchid, setUserbranchid] = useState("")
  const [usertype, setUsertype] = useState("")
  const [userId, setUserid] = useState("")

    const [toggle,  setToggle] = useState(false)
  const [modal, setModal] = useState(false);
  const [yearmodal, setYearmodal] = useState(false);
  const [age, setAge] = React.useState('');

  const [source, setSource] = React.useState([]);
  

  const [loader, setLoader] = React.useState(false);
  const [routeId, setRouteid] = React.useState("");
  const [routename, setRoutename] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [blocked, setBlocked] = React.useState(0)

  const [ledgerid, setLedgerid] = React.useState("");
  const [settingsid, setSettingsid] = React.useState("");
  const [settingstype, setSettingstype] = React.useState("");
  const [settingsname, setSettingsname] = React.useState("");
  const [ledgerlist, setLedgerlist] = React.useState([]);

  const [todate, setTodate] = React.useState(new Date);
  const [fromdate, setFromdate] = React.useState(new Date);
  

  function setData(childData) {
    setToggle(childData)
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const changeSales = (event) => {
      console.log("ledgerid", event.target.value)
    setLedgerid(event.target.value)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  useEffect(() => {
      getData()
    }, []);

    const getData = () => {
      var userdata = JSON.parse(localStorage.getItem("user"))
      var branch = JSON.parse(localStorage.getItem("branch"))
      var company = JSON.parse(localStorage.getItem("company"))
      setUsercompanyid(company)
      setUserbranchid(branch)
      setUsertype(userdata.user_type)
      setUserid(userdata.user_id)
        setLoader(true)
        const data = {
            "companyid": company,
        }
    fetch(global.url + "viewSettings", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("response route", responseJson)
            if(responseJson.error == false){
                setLoader(false)
                setSource(responseJson.data)
            }
            else{
                setLoader(false)
            }
        })
        .catch((error)=>{
            console.log(error)
        })
    }

    const getLedger = () => {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        setUsercompanyid(company)
        setUserbranchid(branch)
        setUsertype(userdata.user_type)
        setUserid(userdata.user_id)
    
        var data = {
          "type":  userdata.user_type,
          "companyid": company
        }
        console.log("ledger data", data)
        fetch(global.url + "viewLedger", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
          .then((response)=> response.json())
          .then((responseJson)=>{
              console.log("response ledger", responseJson)
              if(responseJson.status == "false"){
                  setLoader(false)
                  setLedgerlist(responseJson.data)
              }
              else{
                  setLoader(false)
              }
          })
          .catch((error)=>{
              console.log(error)
          })
      }

    const editClick = (
        settingsid,
        ledgerid, 
        name,
        type,
        companyid
      ) => {
        setModal(true)
        setLedgerid(ledgerid)
        setSettingsname(name)
        setSettingsid(settingsid)
        setSettingstype(type)
        getLedger()
    }

    const onAdd = () => {
        setModal(true)
        setLedgerid("")
        setSettingsid("")
        getLedger()
    }

    const onSave = () => {


      var data = {
          "settings_id": settingsid,
          "settings_name": settingsname,
          "settings_type": settingstype,
          "settings_id_fk": ledgerid,
          "settings_company_id": usercompanyid,
      }
    console.log("data", data)
      fetch(global.url + "updateSettings", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
          console.log("responsejson save settings", responseJson);
          if(responseJson.error == false) {
            getData()
            setModal(false)
            alert(responseJson.message)
          }
          if(responseJson.error == true) {
            alert(responseJson.message)
          }
      })
      .catch((error)=>{
          console.log(error)
      })
    }

    const financialdate = () => {
        setYearmodal(true)
    }

    const dateSubmit = () => {

    }

   

    return(
        <div className="main-content">
          
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <div style={{margin: 20, flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Common Settings</p>
               {/* <Button variant="contained" onClick={onAdd}>Add</Button> */}
               <Button onClick={() => history.push("/finatialyear")} variant="contained">Set Financial Year Date</Button>
            </div>
            
            <TableContainer sx={{ maxHeight: 440 }}>
            {loader ? <Loader /> :
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            
                            <TableBody>
                            {
                            source
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                return (
                                  row.settings_type == 13 || row.settings_type == 14 ? null :
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell style={{fontWeight: 'bold'}}>
                                            {row.settings_name}
                                        </TableCell>
                                        <TableCell >
                                            {row.ledger_name}
                                        </TableCell>
                                        <TableCell >
                                            <a href="javascript:void(0)" onClick={() => 
                                                editClick(row.settings_id, 
                                                row.settings_id_fk, 
                                                row.settings_name, 
                                                row.settings_type, 
                                                )}>
                                                <FaIcons.FaEdit fontSize={20} color="blue" />
                                            </a>
                                        </TableCell>

                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
            }
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={source.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper> 
          <Modal
              size="lg"
              show={modal}
              onHide={() => setModal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Settings
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    
                    <Col xs={12}>
                      <FormControl sx={{ mt: 3, width: '100%' }}>
                              <InputLabel id="demo-simple-select-helper-label">Ledger *</InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={ledgerid}
                                label="Age"
                                onChange={changeSales}
                                size="small"
                              >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              {ledgerlist.map((item, index) => (
                                  <MenuItem value={item.ledger_id}>{item.ledger_name}</MenuItem>
                                ))}
                            </Select>
                      </FormControl>
                    </Col>
                    
                    
                    <Col xs ={12} style={{marginTop:30}}>
                      <Button onClick={onSave} style={{float: 'right'}} variant="contained">Submit</Button>
                      <Button onClick={() => setModal(false)} style={{marginRight: 40, float: 'right'}} variant="contained" color="error">Cancel</Button>
                    </Col>
                    
                  </Row>
              </Modal.Body>
           </Modal>

           <Modal
              size="lg"
              show={yearmodal}
              onHide={() => setYearmodal(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                 Set Financial year Date
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Row>
                    <Col xs={12} md={3} style={{marginTop: 25}}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                  label="From Date"
                                  value={fromdate}
                                  onChange={(newValue) => {
                                    setFromdate(newValue);
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                              />
                          </LocalizationProvider>
                      </Col>
                      <Col xs={12} md={3} style={{marginTop: 25}}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                  label="To Date"
                                  value={todate}
                                  onChange={(newValue) => {
                                    setTodate(newValue);
                                  }}
                                  renderInput={(params) => <TextField {...params} />}
                              />
                          </LocalizationProvider>
                      </Col>
                      <Col xs={12} md={6} style={{marginTop: 25}}>
                        <Button onClick={() => dateSubmit()} variant="contained">Set Financial Year Date</Button>
                      </Col>
                  </Row>
              </Modal.Body>
           </Modal>
      </div>
    )
}
export default Commonsettings;